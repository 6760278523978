@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';

.app__page.property-ledger {
    height: calc(100vh - 72px);
    overflow-y: scroll;
    @include mobile {
        height: calc(100vh - 144px);
        min-height: calc(100vh - 144px);
    }
}

.property-ledger {
    &__header {
        padding: 8px 0 8px;
        margin-bottom: 15px;
        .back-button {
            margin-left: -12px;
        }
        @include mobile {
            padding: 16px 0;
        }
    }

    .property-ledger-container {
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        .properties-ledger-header {
            background-color: #FCFCFC;
            display: flex;
        }

        .react-tabs {
            flex: 1;
            flex-direction: column;
            display: flex;
            .react-tabs__tab-panel {
                display: flex;
            }
            .react-tabs__tab-panel--selected {
                width: 100%;
            }
        }

        .property-ledger__tab-list {
            .react-tabs__tab {
                display: inline-block;
                padding: 6px 12px;
                min-width: 160px;
                @include mobile {
                    min-width: 120px;
                }
                @include fontStyle($Inter, 14px, 18px);
                color: #525252;
                border-bottom: 2px solid #D1D5DB;
                margin-right: 36px;
                cursor: pointer;
    
                &--selected {
                    border-top: 0px;
                    border-left: 0px;
                    border-right: 0px;
                    border-bottom: 2px solid #5A5ECC;
                }
    
                &--disabled {
                    border-top: 0px;
                    border-left: 0px;
                    border-right: 0px;
                    border-bottom: 2px solid #D1D5DB;
                }
            }
            margin-top: 15px;
        }

        .property-ledger__tabs-header-wrapper {
            border-bottom: 1px solid #D1D5DB;
        }

        .tabbed-property-sales__tabs-body-wrapper {
            display: flex;
            flex-direction: column;
            flex: 1;
            .tabbed-property-sales__tabs-body {
                flex: 1;
                display: flex;
            }
        }
    }

    .property-ledger-loader {
        align-self: center;
        width: 100%;
        .ledger-loader {
            display: flex;
            justify-content: center;
        }
    }
}
