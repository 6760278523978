@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';
.base-card {
    position: relative;
    width: 100%;
    &__images {
        position: relative;
        height: 400px;
        border-radius: 24px;
        margin-bottom: 12px;
        @include middleTablet {
            height: 360px;
        }
        @include tablet {
            height: 220px;
        }
        @include mobile {
            height: 270px;
        }
        @include mobileMiddle {
            height: 220px;
        }
        &_header {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 16px;
            z-index: 9;
            & > div {
                display: flex;
                align-items: center;
                gap: 0 8px;
            }
            &-company {
                width: 32px;
                height: 32px;
                border-radius: 50%;
                background-size: cover;
                background-position: center;
            }
        }
        &_img {
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center;
            background-image: url(../../../assets/images/emptyImg.jpeg);
            border-radius: 10px;
        }
    }
    &__content {
        display: flex;
        &_header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 8px;
            &-type {
                display: flex;
                align-items: center;
                h5 {
                    color: $grey700;
                    margin-left: 6px;
                }
            }
        }
        &_body {
            display: flex;
            justify-content: space-between;
            gap: 0 12px;
        }
        h2 {
            padding: 8px 0 24px;
            border-bottom: 1px solid $greyLight;
        }
        h5.renft-link {
            color: $grey700;
        }
        &_column:last-of-type {
            margin-left: auto;
        }
        .re-nft-container {
            height: 44px;
            width: 47px;
            margin-right: 10px;
            @include mobile {
                margin-right: 0px;
            }
        }
    }
}

