@import 'src/assets/styles/mixins';
@import 'src/assets/styles/variables';

.app__page.rent-leveling-summary-page {
    height: calc(100vh - 72px);
    min-height: calc(100vh - 72px);
    @include mobile {
        height: calc(100vh - 144px);
        min-height: calc(100vh - 144px);
    }
}

.rent-leveling-date-selector {
    display: flex;
    align-items: center;
}

.rent-leveling-summary {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    &__rent-leveling-header {
        background-color: #FCFCFC;
        display: flex;
        justify-content: space-between;
        h1 {
            @include fontStyle($Inter, 26px,1.5,500);
            display: flex;
            align-items: center;
        }
        min-height: 48px;
    }

    .rent-leveling-summary-body-wrapper {
        background-color: #f5f5f5;
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    .rent-leveling-summary-table-container {

        &.container {
            margin-bottom: 72px;
        }
    }
}
