@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';

.fractionalization {
    background-color: rgba(251, 251, 251, 1);
    padding-bottom: 20px;
    @include mobile {
        padding-bottom: 0;
    }
    &__box {
        border: 1px solid $greyLight;
        background-color: $white;
        box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.02);
        border-radius: 16px;
        margin: 16px 0 36px;
        padding: 40px;
        @include tabletMini {
            padding: 20px;
        }
        & > p {
            font-size: 18px;
            line-height: 1.2;
            color: $grey700;
            margin: 24px 0 48px;
            @include tabletMini {
                font-size: 16px;
                margin: 20px 0 30px;
            }
        }
        & > img {
            margin-bottom: 40px;
        }
        &_button {
            display: flex;
            justify-content: center;
        }
    }
    &__text {
        font-size: 18px;
        line-height: 1.2;
        color: $black;
        margin-bottom: 36px;
        @include tabletMini {
            font-size: 16px;
        }
        button {
            font-size: 18px;
            line-height: 1.2;
            color: $violet;
            @include tabletMini {
                font-size: 16px;
            }
        }
    }
    &__footer {
        border-top: 1px solid $grey300;
        background-color: $violetLight;
        padding: 40px 0;
        @include tabletMini {
            padding: 30px 0;
        }
        h6 {
            font-size: 24px;
            line-height: 1.25;
            color: $black;
            margin-bottom: 4px;
            @include tabletMini {
                font-size: 20px;
            }
        }
        p {
            font-size: 14px;
            line-height: 1.25;
            color: $grey700;
            margin-bottom: 20px;
        }
        &_partners {
            display: flex;
            gap: 0 40px;
            img {
                width: calc((100% - 80px) / 3);
            }
            @include tabletMini {
                flex-wrap: wrap;
                gap: 20px;
                img {
                    width: calc((100% - 40px) / 3);
                }
            }
            @include mobile {
                img {
                    width: 100%;
                    max-width: 300px;
                    margin: 0 auto;
                }
            }
        }
    }
}
