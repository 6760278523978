@import 'src/assets/styles/mixins';
@import 'src/assets/styles/variables';

.user-administration-modal {
    width: 80%;
    min-height: 300px;
    min-width: 500px;
    height: 70%;
    .user-administration-modal-container {
        overflow-y: auto;
        margin: 20px 60px;
        display: flex;
        flex-direction: column;
        flex: 1
    }
    .user-administration-modal-description {
        margin-top: 48px;
        margin-bottom: 24px;
    }
    .user-administration-modal-main {
        flex: 1;
        h4 {
            color: #525252;
        }
    }
    .user-administration-modal-buttons {
        justify-content: flex-end;
        display: flex;
        button {
            width: 200px;
            border-radius: 0px;
            font-size: 14px;
            line-height: 18px;
            height: 48px;
            text-align:left;
            padding-left: 1.6rem;
            padding-right: 1.6rem;
        }

        button.user-administration-cancel {
            background-color: $greyButtonBackground;
        }
        button.user-administration-add {
            margin-left: 10px;
            background-color: $violet;
        }
        button.user-administration-add:disabled {
            background-color: #c6c6c6;
            color: #8d8d8d;
        }
    }
}
