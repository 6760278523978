.blockchain-overview {
    :global {
        .awsui-grid {
            @media (max-width: 768px) {
                .awsui-grid-column {
                    width: 100% !important;
                }
            }
        }
    }

    &__info-row {
        margin-bottom: 20px;

        @media (max-width: 768px) {
            margin-bottom: 16px;
        }

        .label {
            font-size: 14px;
            color: #545b64;
            margin-bottom: 4px;

            @media (max-width: 768px) {
                font-size: 12px;
            }
        }

        .value-container {
            display: flex;
            align-items: center;
            gap: 8px;

            @media (max-width: 768px) {
                flex-wrap: wrap;
            }

            .value {
                font-family: monospace;
                font-size: 16px;
                word-break: break-all;
                @media (max-width: 768px) {
                    font-size: 14px;
                }
                
                &.link {
                    color: #0972d3;
                    text-decoration: none;
                    
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    &__error {
        color: #d91515;
        background: #fdf3f3;
        padding: 16px;
        border-radius: 8px;
        border: 1px solid #f1baba;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;

        @media (max-width: 768px) {
            flex-direction: column;
            align-items: stretch;
            padding: 12px;
            gap: 12px;
        }
    }

    .metadata-section {
        margin-top: 24px;

        @media (max-width: 768px) {
            margin-top: 16px;
        }
    }
}
