@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';
.doc {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 26px;
    background-color: $white;
    border-radius: 16px;
    cursor: pointer;
    border: 1px solid $greyLight;
    box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.02);
    &--disabled {
        cursor: default;
    }
    @include tablet {
        padding: 15px 20px;
    }
    @include mobile {
        padding: 15px;
    }
    &__left {
        display: flex;
        align-items: center;
        &_file {
            display: flex;
            align-items: center;
            position: relative;
            &_name {
                position: absolute;
                top: 58%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: $white;
                text-transform: uppercase;
                font-size: 11px;
                font-weight: 700;
            }
        }
        &_text {
            margin: 0 7px 0 17px;
            h3 {
                font-weight: 600;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                line-break: anywhere;
            }
            h5 {
                color: $grey600;
            }
        }
    }
}
