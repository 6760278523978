@import 'src/assets/styles/mixins';
@import 'src/assets/styles/variables';

.tabbed-general-stats {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    &__grid {
        display: flex;
        flex-wrap: wrap;
        gap: 0 30px;

        .highlight-grid-item {
            h3 {
                color: #DE1B00;
            }
        }

        h3 {
            font-size: 21px;
        }

        h4 {
            font-size: 14px;
            font-weight: 400;
        }

        .tabbed-general-stats__grid_item {
            border-left: 4px solid #d9d9d9;
            padding-left: 30px;
        }

        &_item {
            width: calc((100% - 30px) / 2);
            @include mobile {
                width: 100%;
                order: 2;
            }
            &--map {
                border-radius: 16px;
                overflow: hidden;
                @include mobile {
                    height: 360px;
                    margin-bottom: 32px;
                    order: 1;
                }
            }
        }
        &--five {
            padding: 6rem 0;
            gap: 0;
            @include desktop {
                padding: 40px 0;
            }
            @include tablet {
                gap: 16px 0;
            }
            @include mobile {
                padding: 20px;
            }
            .tabbed-general-stats__grid_item {
                width: calc(100% / 5);
                padding-right: 10px;
                @include tablet {
                    width: calc(100% / 3);
                }
                @include mobile {
                    width: 100%;
                }
            }
        }
        &--nine {
            padding-top: 2rem;
            gap: 0;
            @include desktop {
                padding: 35px 0;
            }
            @include tablet {
                gap: 24px 0;
            }
            .tabbed-general-stats__grid_item {
                flex: 1;
                width: calc(100% / 8);
                padding-right: 10px;
                &--small {
                    flex: 0.7;
                    @include tablet {
                        flex: auto;
                    }
                }
                &--big {
                    flex: 1.3;
                    @include tablet {
                        flex: auto;
                    }
                }
                @include tablet {
                    width: calc(100% / 4);
                    flex: auto;
                }
                @include mobile {
                    width: 50%;
                }
            }
        }
        &--four {
            gap: 0 3.2rem;
            @include tabletMini {
                gap: 32px;
            }
            .tabbed-general-stats__grid_item {
                width: calc((100% - 9.6rem) / 4);
                @include tabletMini {
                    width: calc((100% - 32px) / 2);
                }
                @include mobile {
                    width: 100%;
                }
            }
            &-small {
                gap: 8px;
                .tabbed-general-stats__grid_item {
                    width: calc((100% - 24px) / 4);
                    height: 100px;
                    @include tabletMini {
                        width: calc((100% - 16px) / 3);
                    }
                    @include mobile {
                        width: 100%;
                        height: 90px;
                        order: initial;
                    }
                    &--full {
                        width: 100%;
                    }
                }
            }
        }
        &--two {
            gap: 0 3.2rem;
            @include tabletMini {
                gap: 32px;
            }
            .tabbed-general-stats__grid_item {
                width: calc((100% - 9.6rem) / 2);
                @include tabletMini {
                    width: calc((100% - 32px) / 2);
                }
                @include mobile {
                    width: 100%;
                }
            }
            &-small {
                gap: 8px;
                .tabbed-general-stats__grid_item {
                    width: calc((100% - 24px) / 2);
                    height: 100px;
                    @include tabletMini {
                        width: calc((100% - 16px));
                    }
                    @include mobile {
                        width: 100%;
                        height: 90px;
                        order: initial;
                    }
                    &--full {
                        width: 100%;
                    }
                }
            }
        }
    }
}
