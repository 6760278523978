@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';

.tabs {
    position: relative;
    width: 100%;
    display: flex;
    // overflow-x: auto;
    // &::-webkit-scrollbar {
    //     width: 0;
    //     height: 0;
    // }
    @include mobile {
        flex-wrap: wrap;
    }
    &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        content: '';
        width: 100%;
        height: 2px;
        background: $greyLight;
    }
    &__item {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0 12px;
        min-width: 20.8rem;
        height: 4rem;
        cursor: pointer;
        @include middleTablet {
            min-width: 17rem;
        }
        @include tabletMini {
            min-width: 100px;
            padding: 0 15px;
        }
        @include mobile {
            width: 50%;
        }
        &::after {
            position: absolute;
            bottom: 0;
            left: 0;
            content: '';
            width: 0;
            height: 2px;
            background: $violet;
            transition: all 0.3s ease-in-out;
            z-index: 2;
        }
        h4 {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0 4px;
            font-weight: 600;
            color: $grey600;
            white-space: nowrap;
            @include tabletMini {
                font-size: 14px;
            }
            @include mobile {
                font-size: 13px;
            }
        }
        &--active {
            &::after {
                width: 100%;
            }
            h4 {
                color: $black;
            }
        }
    }
}
