@import 'src/assets/styles/mixins';
@import 'src/assets/styles/variables';

.notification-settings {
    height: calc(100vh - 72px);
    @include mobile {
        height: calc(100vh - 144px);
    }
    display: flex;
    flex-direction: column;

    &__content {
        flex: 1;
        display: flex;
        flex-direction: column;
        .notification-settings-header {
            margin-top: 40px;
            margin-bottom: 32px;
            max-width: 435px;
            h1 {
                @include fontStyle($Inter, 26px,1.5,500);
            }
        }

        &.notification-settings-loading {
            align-items: center;
            justify-content: center;
        }
    }
}
