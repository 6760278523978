@import 'src/assets/styles/mixins';
@import 'src/assets/styles/variables';

.mobile-nav-select {
    display: none;
    @include mobile {
        display: block;
    }
    .base-select {
        &__content {
            min-width: 220px;
            max-width: 220px;
        }
        &__select {
            h4.base-select__select-input {
                @include fontStyle($ff: $Roboto, $fs: 20px);
            }
    
        }
        .dropdown__list_item {
            @include fontStyle($ff: $Roboto, $fs: 20px);
        }
    }
   flex: 1;
}

.header-nav-bar-desktop {
    @include mobile {
        display: none;
    }
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;

    font-size: 20px;
    span {
        @include fontStyle($ff: $Roboto, $fs: 20px);
        margin-left: 17px;
        margin-right: 17px;
        cursor: pointer;
        padding-bottom: 5px
    }

    span.active {
        border-bottom: 1px solid $navigationBlue;
    }
}
