@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';
.card {
    position: relative;
    width: 100%;
    border-radius: 16px;
    border: 1px solid $greyLight;
    background-color: $white;
    box-shadow: 0px 69px 80px -42px rgba(0, 0, 0, 0.1);
    max-height: 356px;
    cursor: pointer;
    @include middleTablet {
        max-height: 336px;
    }
    @include tablet {
        max-height: 326px;
    }
    @include mobile {
        max-height: 343px;
    }
    &--small {
        width: 320px;
        @include tablet {
            width: 210px;
        }
        .card__top {
            padding: 10px;
            &_images {
                height: 175px;
                @include tablet {
                    height: 100px;
                    margin-bottom: 8px;
                    &-header {
                        padding: 4px;
                    }
                }
                @include mobile {
                    height: 115px;
                }
            }
            &_content {
                @include tablet {
                    &-type {
                        font-size: 12px;
                        margin-bottom: 6px;
                    }
                    h4 {
                        font-size: 12px;
                        line-height: 1;
                    }
                }
            }
        }
    }
    @include mobile {
        max-height: 405px;
    }
    &__top {
        padding: 16px 16px 12px;
        @include tablet {
            padding: 12px 12px 18px;
        }
        @include mobile {
            padding: 10px;
        }
        &_images {
            position: relative;
            height: 185px;
            border-radius: 10px;
            margin-bottom: 12px;
            @include middleTablet {
                height: 165px;
            }
            @include tablet {
                height: 155px;
            }
            @include mobile {
                height: 180px;
                margin-bottom: 6px;
            }
            @include mobileMiddle {
                height: 220px;
            }
            &-header {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                width: 100%;
                display: flex;
                justify-content: space-between;
                padding: 8px;
                z-index: 9;
                & > div {
                    display: flex;
                    align-items: center;
                    gap: 0 8px;
                }
                &-company {
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    background-size: cover;
                    background-position: center;
                }
                &-featured {
                    width: 3.2rem;
                    height: 3.2rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    background: $white;
                    color: $violet;
                }
            }
            &-img {
                width: 100%;
                height: 100%;
                background-size: cover;
                background-position: center;
                background-image: url(../../../assets/images/emptyImg.jpeg);
                border-radius: 10px;
            }
        }
        &_content {
            &-type {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 8px;
                h6 {
                    display: flex;
                    align-items: center;
                    gap: 0 6px;
                    color: $grey700;
                    font-weight: 500;
                    margin-left: 6px;
                }
                h5 {
                    color: $violet;
                    font-weight: 500;
                }
            }
            h4 {
                width: 100%;
                font-weight: 600;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                @include mobile {
                    font-size: 14px;
                    line-height: 1;
                }
            }
        }
    }
    &__bottom {
        position: relative;
        width: 100%;
        display: flex;
        border-top: 1px solid $greyLight;
        &_item {
            padding: 12px 16px 18px;
            border-right: 1px solid $greyLight;
            @include tablet {
                padding: 12px 12px 18px;
            }
            h6 {
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: $grey600;
                margin-bottom: 4px;
                span {
                    @include fontStyle($fs: 10px, $lh: 1.2);
                    color: $grey;
                }
            }
            h5 {
                font-weight: 700;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
            p {
                display: flex;
                align-items: center;
                svg {
                    display: inline-block;
                    margin-right: 4px;
                }
            }
            a {
                @include fontStyle($fs: 14px, $fw: 500);
                color: $violet;
                text-decoration: underline;
                &:hover {
                    opacity: 0.6;
                }
            }
            &:last-of-type {
                border: none;
            }
        }
        &--one {
            .card__bottom_item {
                width: 100%;
            }
        }
        &--two {
            .card__bottom_item {
                width: 42%;
                &:first-of-type {
                    width: 58%;
                }
            }
        }
        &--three {
            .card__bottom_item {
                width: 21%;
                &:first-of-type {
                    flex: 58%;
                }
            }
        }
        &--last {
            border-left: 1px solid $greyLight;
            border-right: 1px solid $greyLight;
            border-bottom: 1px solid $greyLight;
            border-bottom-left-radius: 16px;
            border-bottom-right-radius: 16px;
            background-color: $white;
            z-index: 10;
            width: calc(100% + 2px);
            margin-left: -1px;
        }
    }
}
