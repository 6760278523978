@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';

.property-header {
    padding: 24px 0;
    
    @include mobile {
        padding: 16px 0;
    }

    &__breadcrumb {
        display: flex;
        align-items: center;
        color: #525252;
        font-size: 14px;
        margin-bottom: 24px;

        @include mobile {
            margin-bottom: 16px;
            font-size: 12px;
        }

        .breadcrumb-municipality {
            color: #5A5ECC;
            cursor: pointer;
        }

        .breadcrumb-separator {
            margin: 0 8px;
        }
    }

    &__content {
        display: grid;
        grid-template-columns: auto auto;
        align-items: flex-start;
        gap: 78px;
        justify-content: start;

        @include mobile {
            grid-template-columns: 1fr;
            gap: 24px;
        }
    }

    &__info {
        min-width: 0;
    }

    &__address {
        font-size: 24px;
        font-weight: 600;
        color: #111827;
        margin: 0 0 12px 0;

        @include mobile {
            font-size: 20px;
            margin-bottom: 8px;
        }
    }

    &__details {
        display: flex;
        gap: 12px;
        align-items: center;

        .property-type {
            color: #525252;
            font-size: 14px;
        }

        .status-badge {
            background: #FEE2E2;
            color: #991B1B;
            padding: 2px 8px;
            border-radius: 4px;
            font-size: 12px;
            font-weight: 500;
        }
    }

    &__blockchain-info {
        display: flex;
        align-items: center;
        gap: 16px;
        background: #F9FAFB;
        padding: 12px 16px;
        border-radius: 8px;
        min-width: max-content;

        @include mobile {
            flex-direction: column;
            align-items: flex-start;
            gap: 12px;
            width: 100%;
            min-width: unset;
        }

        .renft-mark {
            height: 32px;
            border-right: 1px solid #E5E7EB;
            padding-right: 16px;

            @include mobile {
                border-right: none;
                border-bottom: 1px solid #E5E7EB;
                padding: 0 0 12px 0;
                width: 32px;
            }
        }

        .blockchain-details {
            display: flex;
            gap: 24px;

            @include mobile {
                flex-direction: column;
                gap: 12px;
                width: 100%;
            }

            .info-item {
                display: flex;
                flex-direction: column;
                gap: 4px;

                @include mobile {
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                }

                .label {
                    font-size: 12px;
                    color: #6B7280;
                    text-transform: uppercase;
                    letter-spacing: 0.05em;
                }

                .value-container {
                    display: flex;
                    align-items: center;
                    gap: 8px;

                    .value {
                        font-size: 14px;
                        color: #5A5ECC;
                        text-decoration: none;
                        position: relative;

                        &.monospace {
                            font-family: monospace;
                        }

                        &:hover {
                            &::after {
                                width: 100%;
                            }
                        }

                        &::after {
                            content: '';
                            position: absolute;
                            bottom: -2px;
                            left: 0;
                            width: 0;
                            height: 1px;
                            background-color: #5A5ECC;
                            transition: width 0.2s ease-in-out;
                        }
                    }
                }
            }
        }
    }
} 
