@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';

.notification {
    position: relative;
    &__counter {
        position: absolute;
        top: -3px;
        right: -3px;
        background: $red;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        min-width: 15px;
        min-height: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        @include fontStyle($fs: 10px, $fw: 500, $lh: 1);
        color: $white;
    }
}
