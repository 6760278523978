@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';

.municipality-header-logo-container {
    display: flex;
}
.municipality-header-logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px 0;
    background: $white;
    height: 48px;
    img {
        width: 48px;
        height: auto;
    }
    h2 {
        text-align: center;
        text-transform: capitalize;
        font-size: 32px;
        margin-left: 7px;;
    }
}
