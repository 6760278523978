.blockchain-tab {
    padding: 16px;
    overflow-x: hidden;

    &__title {
        font-size: 24px;
        margin-bottom: 16px;

        @media (max-width: 768px) {
            font-size: 20px;
            margin-bottom: 12px;
        }
    }

    &__description {
        font-size: 16px;
        line-height: 1.5;
        margin-bottom: 24px;
        color: #545b64;

        @media (max-width: 768px) {
            font-size: 14px;
            margin-bottom: 20px;
        }
    }

    :global {
        .awsui-tabs-container {
            @media (max-width: 768px) {
                padding: 0;
            }
        }

        .awsui-tabs-header-list {
            @media (max-width: 768px) {
                flex-wrap: wrap;
                gap: 8px;
            }
        }
    }
} 
