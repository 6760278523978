@keyframes highlight-flash {
    0% {
        background-color: transparent;
    }
    25% {
        background-color: rgba(147, 112, 219, 0.2);
    }
    75% {
        background-color: rgba(147, 112, 219, 0.2);
    }
    100% {
        background-color: transparent;
    }
}

li[class*="awsui_card_"].highlight-card {
    > div[class*="awsui_card-inner_"] {
        animation: highlight-flash 4s ease;
        background-color: transparent;
        border-radius: 16px;
    }
}

.property-ledger-tab-container {
    padding-top: 30px;
}

.document-card-interaction-button {
    float: right;
}

.add-new-document {
    display: inline-block;
    margin-bottom: 6px;
    margin-left: 24px;
}

.documents-tab-h1 {
    display: inline-block;
    h1 {
        display: inline-block;
        font-size: 32px;
    }
}

.documents-tab-user-uploaded-property-documents {
    padding-top: 32px;
    padding-bottom: 16px;
}

.documents-tab-other-property-documents {
    padding-top: 32px;
    padding-bottom: 16px;
}
