@import 'src/assets/styles/variables';

.footer {
    background-color: $grey250;
    height:70px;
    min-height: 70px;
    z-index: 10;
    &__content {
        display: flex;
        height: 100%;
        align-items: center;
    }
}
