@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';

.upload-button {
    position: relative;
    input {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }
    width: 100%;
    height: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 8px;
    padding: 0 12px;
    border-radius: 16px;
    border: 1px solid $violet;
    box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.02);
    @include fontStyle($fs: 16px, $lh: 1.25, $fw: 500);
    color: $violet;

    span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        background: transparent;
        margin: 0;
        transform: rotate(45deg);
        svg {
            width: 100%;
            height: 100%;
            path {
                fill: red;
            }
        }
    }
    &:hover,
    &:active,
    &:focus {
        opacity: 0.5;
    }
}
