@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';

.municipality-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px 0;
    background: $white;
    img {
        width: 8.8rem;
        height: auto;
    }
    h2 {
        text-align: center;
        text-transform: capitalize;
        @include fontStyle($ff: $AcademyEngravedStd, $fs: 32px, $lh: 1.28);
    }
}
