@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';
.map {
    height: 100%;
    width: 100%;
}
.marker {
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    width: 24px;
    height: 24px;
    user-select: none;
    transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    z-index: 8;
    &--active {
        z-index: 9;
        & > svg {
            position: relative;
            transform: scale(2);
            transform-origin: bottom center;
            z-index: 9;
        }
    }
}

.clusters-group {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    padding: 8px;
    text-align: center;
    font-size: 14px;
    color: #fff;
    border: 2px solid #fff;
    border-radius: 50%;
    background-color: $violet;
    animation: easyMove 0.3s;
}

@keyframes easyMove {
    from {
        transform: translateY(-20%);
        opacity: 0;
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.gm-style .gm-style-iw-d {
    overflow: auto !important;
}

.gm-style .gm-style-iw-c {
    padding: 0;
    box-shadow: none;
    background: transparent;
    overflow: initial;
    button {
        display: inline-flex !important;
        align-items: center;
        justify-content: center;
        background-color: $violet !important;
        top: 20px !important;
        right: 0 !important;
        width: 32px !important;
        height: 32px !important;
        transform: translate(100%, 0) !important;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        opacity: 1;
        span {
            width: 20px !important;
            height: 20px !important;
            margin: 0 !important;
            background-color: $white;
        }
    }
}
