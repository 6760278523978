.documents-overview {
    &__file-name {
        font-weight: 500;
        color: #0972d3;
        word-break: break-word;
    }

    &__document-type {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
        background: #f2f3f3;
        padding: 4px 8px;
        border-radius: 4px;
        color: #5f6b7a;
        display: inline-block;
    }

    &__date {
        color: #5f6b7a;
        font-size: 13px;
        white-space: nowrap;
    }

    &__hash {
        font-family: monospace;
        font-size: 13px;
        color: #5f6b7a;
        display: flex;
        align-items: center;
        gap: 8px;
        flex-wrap: wrap;

        &-text {
            font-family: monospace;
            font-size: 13px;
            word-break: break-all;
        }

        .copy-button {
            background: none;
            border: none;
            padding: 8px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #5f6b7a;
            min-width: 32px;
            min-height: 32px;

            &:hover {
                color: #0972d3;
            }

            @media (max-width: 768px) {
                padding: 12px;
            }
        }
    }

    &__version-history {
        &-item {
            border-bottom: 1px solid #e9ebed;
            padding: 16px;

            &:last-child {
                border-bottom: none;
            }
        }
    }

    &__error {
        color: #d91515;
        background: #fdf3f3;
        padding: 16px;
        border-radius: 8px;
        border: 1px solid #f1baba;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;

        @media (max-width: 768px) {
            flex-direction: column;
            align-items: stretch;
            padding: 12px;
            gap: 12px;
        }
    }

    &__verification {
        &-value {
            font-family: monospace;
            font-size: 13px;
            padding: 4px 8px;
            background: #f2f3f3;
            border-radius: 4px;
            color: #000;
            display: inline-block;
            margin-top: 4px;
            word-break: break-all;
        }

        &-match {
            color: #037f0c;
            font-weight: 500;
        }

        &-mismatch {
            color: #d91515;
            font-weight: 500;
        }
    }

    &__timeline {
        position: relative;
        padding: 16px 0;

        @media (max-width: 768px) {
            padding: 12px 0;
        }
    }

    &__timeline-item {
        display: flex;
        gap: 16px;
        position: relative;

        @media (max-width: 768px) {
            gap: 12px;
        }
    }

    &__timeline-connector {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 24px;
        width: 24px;
        position: relative;
    }

    &__timeline-dot {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: #0972d3;
        z-index: 1;
    }

    &__timeline-line {
        position: absolute;
        top: 30px;
        bottom: -30px;
        width: 2px;
        background: #e9ebed;
        left: 50%;
        transform: translateX(-50%);
    }

    &__version-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        flex-wrap: wrap;

        @media (max-width: 768px) {
            gap: 8px;
        }
    }

    &__version-date {
        color: #5f6b7a;
        font-size: 13px;
        white-space: nowrap;
    }

    &__version-history-item {
        flex: 1;
        background: #ffffff;
        border: 1px solid #e9ebed;
        border-radius: 8px;
        margin-bottom: 16px;

        @media (max-width: 768px) {
            margin-bottom: 12px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__verify-button {
        min-width: 165px !important;
        span {
            white-space: nowrap;
        }
    }

    :global {
        .awsui-table-container {
            table {
                min-width: 1000px;
            }
        }

        .awsui-table-header {
            @media (max-width: 768px) {
                padding: 12px;
            }
        }

        .awsui-table-cell {
            @media (max-width: 768px) {
                padding: 8px 12px;
            }
        }
    }
}
