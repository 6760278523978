@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';

.property-ledger-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.ledger-loader {
    margin: 48px 0;
}

.property-summary {
    overflow-x: hidden;

    &__loading {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 200px;
        font-size: 1.2em;
        color: #666;
    }

    &__grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        gap: 1rem;
        margin-bottom: 2rem;

        &--nine {
            display: grid;
            grid-template-columns: repeat(7, 1fr);
            gap: 2rem;
            padding: 4.5rem 2rem;
            width: 100%;

            @include desktop {
                padding: 35px 0;
            }

            @include tablet {
                grid-template-columns: repeat(4, 1fr);
                gap: 24px 0;
            }

            @include mobile {
                grid-template-columns: repeat(2, 1fr);
                padding: 20px;
            }
        }

        &-item {
            &--map {
                min-height: 300px;
                grid-column: 1 / -1;
                margin-bottom: 3rem;

                @include mobile {
                    min-height: 200px;
                    margin-bottom: 2rem;
                }
            }
        }
    }

    &__map-photo-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        min-height: 300px;

        @include mobile {
            grid-template-columns: 1fr;
            gap: 16px;
            min-height: auto;
        }
    }

    &__photo {
        width: 100%;
        height: 100%;
        
        &-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 8px;

            @include mobile {
                height: 200px;
            }
        }
    }

    &__map {
        width: 100%;
        height: 100%;
        min-height: 300px;

        @include mobile {
            min-height: 200px;
        }
    }
}

.bg-violet {
    background-color: #FCFCFC;
    border-bottom: 1px solid #E5E7EB;
    margin-bottom: 2rem;

    @include mobile {
        margin-bottom: 1rem;
    }
}
