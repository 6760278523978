@import 'src/assets/styles/mixins';
@import 'src/assets/styles/variables';

.product-permissions {
    height: calc(100vh - 72px);
    @include mobile {
        height: calc(100vh - 144px);
    }
    display: flex;
    flex-direction: column;

    &__content {
        flex: 1;
        display: flex;
        flex-direction: column;

        &.product-permissions-loading {
            align-items: center;
            justify-content: center;
        }
    }

    .product-permissions-no-users-found {
        font-weight: 500;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .product-permissions-manage-button {
        button {
            border-radius: 0px;
            background-color: #393939;
            height: 32px;
            font-size: 14px;
            line-height: 18px;
            padding-left: 1.6rem;
            padding-right: 1.6rem;
        }
    }

    .user-administration-modal {
        .product-permissions-module-row {
            display: flex;
            height: 40px;
            font-size: 12px;
            line-height: 20px;
            border-top: 1px solid #E0E0E0;
            align-items: center;
            .radio {
                margin-left: 20px;
            }
            .product-permissions-module-row-module-name {
                width: 200px;
            }
        }
    }
}
