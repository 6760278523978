@import 'src/assets/styles/variables';
.docs-tabs {
    &__header {
        display: flex;
        align-items: center;
        gap: 8px;
        flex-wrap: wrap;
        margin-bottom: 8px;
        button {
            gap: 0 8px;
            span {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                width: 20px;
                height: 20px;
                min-width: 20px;
                min-height: 20px;
                border-radius: 50%;
                font-size: 10px;
                font-weight: 500;
                background: $violet;
                color: $white;
                text-align: center;
                &.grey {
                    background: $grey600;
                }
            }
        }
    }
}
