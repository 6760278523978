@import 'src/assets/styles/variables';
.dots-overlay {
    position: relative;
    display: flex;
    align-items: center;
    height: 40px;
}
.dots {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 4px;
    cursor: pointer;
    span {
        display: inline-flex;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: $grey700;
    }
}
