@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';
.search-city {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    background: $greyLight;
    border: 1px solid $grey200;
    border-radius: 16px;
    padding: 13px 2rem 8px;
    height: 64px;
    transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    @include tabletMini {
        padding: 10px 12px 7px;
        height: 60px;
    }
    &__label {
        @include fontStyle($fs: 16px, $lh: 1.125, $fw: 500);
        color: $grey600;
        margin-bottom: 4px;
        &__error {
            border: 1px solid red;
            margin-bottom: 10px;
        }
    }
    &__content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        &_text {
            @include fontStyle($fs: 14px, $lh: 1.58, $fw: 400);
            color: $black;
        }
        &_icon {
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translate(0%, -50%) rotate(-90deg);
            width: 16px;
            height: 16px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
            &--open {
                transform: translate(0%, -50%) rotate(90deg);
            }
        }
    }
    &__select {
        position: absolute;
        left: 0;
        bottom: 0;
        transform: translateY(100%);
        width: 100%;
        z-index: 99;
        &_content {
            margin-top: 10px;
            background-color: $white;
            border: 1px solid $grey200;
            border-radius: 16px;
            max-height: 200px;
            overflow-y: auto;
            &-item {
                padding: 10px 2rem;
                &:hover {
                    background-color: $grey200;
                }
            }
        }
    }
}
