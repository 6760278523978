@import 'src/assets/styles/mixins';
@import 'src/assets/styles/variables';

.user-management {
    height: calc(100vh - 72px);
    @include mobile {
        height: calc(100vh - 144px);
    }
    display: flex;
    flex-direction: column;

    &__content {
        flex: 1;
        display: flex;
        flex-direction: column;

        &.user-management-loading {
            align-items: center;
            justify-content: center;
        }
    }

    .user-management-header-container {
        display: flex;
        align-items: center;
    }
    .user-management-h1-container {
        h1 {
            font-size: 24px;
            line-height: 1.5;
            font-weight: 500;
        }
    }

    .user-admin-remove-button {
        button {
            border-radius: 0px;
            background-color: #DA1E28;
            height: 32px;
            font-size: 14px;
            line-height: 18px;
            padding-left: 1.6rem;
            padding-right: 1.6rem;
        }
    }

    .add-user-button {
        button {
            border-radius: 0px;
            font-size: 14px;
            line-height: 18px;
            padding-left: 1.6rem;
            padding-right: 1.6rem;
        }
        margin-left: auto;
        margin-right: 150px;
        span.user-button-text {
            margin-right: 32px;
        }
    }

    .user-management-no-users-found {
        font-weight: 500;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .user-administration-modal {
        .user-administration-modal-main {
            .base-select__select {
                min-width: 400px;
                max-width: 400px;
            }
        }
    }
}
