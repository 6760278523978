@import 'src/assets/styles/mixins';
@import 'src/assets/styles/variables';

.app__page.tabbed-property-sales-page {
    height: calc(100vh - 72px);
    min-height: calc(100vh - 72px);
    @include mobile {
        height: calc(100vh - 144px);
        min-height: calc(100vh - 144px);
    }
}

.tabbed-property-sales {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .react-tabs {
        flex: 1;
        flex-direction: column;
        display: flex;
    }

    .property-sales-no-sales-in-range {
        font-weight: 500;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__properties-header {
        background-color: #FCFCFC;
        display: flex;
        h1 {
            @include fontStyle($Inter, 26px,1.5,500);
            display: flex;
            align-items: center;
        }
        .tabbed-property-sales-date-selector {
            margin-left: auto;
            display: flex;
        }
        min-height: 48px;
        @include mobile {
            flex-direction: column;
        }
        .base-select {
            &__select {
                &--custom-l {
                    max-width: 180px;
                    min-width: 180px;
                }
            }
        }

    }

    &__tab-list {

        .react-tabs__tab {
            display: inline-block;
            padding: 6px 12px;
            min-width: 160px;
            @include mobile {
                min-width: 120px;
            }
            @include fontStyle($Inter, 14px, 18px);
            color: #525252;
            border-bottom: 3px solid #E0E0E0;
            margin-right: 36px;
            cursor: pointer;

            &--selected {
                border-top: 0px;
                border-left: 0px;
                border-right: 0px;
                border-bottom: 3px solid #5A5ECC;
            }

            &--disabled {
                border-top: 0px;
                border-left: 0px;
                border-right: 0px;
                border-bottom: 3px solid #E0E0E0;
            }
        }
        margin-top: 15px;
        border-bottom: 1px solid #E0E0E0;
    }

    .csv-button-download-row {
        @include mobile {
            justify-content: center;
            margin-bottom: 16px
        }
        display: flex;
        justify-content: flex-end;
        align-items: center;
        button {
            padding-left: 16px;
            padding-right: 64px;
            background-color: $greyButtonBackground;
            border-radius: 0px;
            border: none;
            font-size: 14px;
        }

        padding-bottom: 80px;
    }

    .tabbed-property-sales__tabs-body-wrapper {
        background-color: #f5f5f5;
        display: flex;
        flex-direction: column;
        flex: 1;
    }
}
