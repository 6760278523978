@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';
.profile-menu {
    &__content {
        position: relative;
        cursor: pointer;
        &_user {
            justify-content: right;
            padding-right: 20px;
            min-width: 24rem;
            display: flex;
            align-items: center;
            gap: 0 12px;
            @include mobile {
                min-width: 0;
            }
            &-picture {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 4.8rem;
                height: 4.8rem;
                border-radius: 50%;
                color: $violet;
                background-size: cover;
                background-position: center;
                text-transform: uppercase;
                @include mobile {
                    width: 30px;
                    height: 30px;
                    font-size: 12px;
                }
            }
            &-text {
                @include mobile {
                    display: none;
                }
            }
            h4 {
                display: flex;
                align-items: center;
                gap: 0 6px;
                color: $black;
                font-weight: 500;
                line-height: 1.18;
                @include mobile {
                    font-size: 12px;
                }
            }
            h5 {
                color: $grey600;
                @include mobile {
                    font-size: 12px;
                }
            }
        }
        &_dropdown {
            &--open {
                display: block;
                position: absolute;
                transform: translateY(100%);
                transition: 1s ease-in-out;
                width: 100%;
                bottom: 0;
                z-index: 99;
                @include tabletMini {
                    min-width: 150px;
                    right: 0;
                }
            }
            &--close {
                display: none;
                width: 100%;
                background: #ffffff;
                border-radius: 8px;
                transition: 1s ease-in-out;
            }
            &-list {
                display: flex;
                flex-direction: column;
                gap: 24px 0;
                width: 100%;
                margin-top: 8px;
                background-color: $white;
                padding: 24px;
                border: 1px solid $grey200;
                border-radius: 8px;
                overflow: hidden;
                overflow-y: auto;
                @include mobile {
                    gap: 12px 0;
                    padding: 12px;
                }
                &-item {
                    @include fontStyle($ff: $Urbanist, $fs: 16px, $fw: 500, $lh: 1.18);
                    color: $black;
                    transition: all 0.3s ease-in;
                    &--active,
                    &:hover {
                        color: $violet;
                        opacity: 1;
                    }
                    @include mobile {
                        font-size: 14px;
                    }
                }
                li {
                    display: flex;
                    align-items: center;
                    gap: 0 8px;
                    @include fontStyle($ff: $Urbanist, $fs: 16px, $fw: 500, $lh: 1.18);
                    color: $black;
                    transition: all 0.3s ease-in;
                    cursor: pointer;
                    @include mobile {
                        font-size: 14px;
                        gap: 0 4px;
                    }
                    &:hover {
                        color: $violet;
                    }
                    &:last-of-type {
                        padding-top: 16px;
                        border-top: 1px solid $greyLight;
                        @include mobile {
                            padding-top: 8px;
                        }
                    }
                }
                li.solo-list-element {
                    &:last-of-type {
                        padding-top: 0px;
                        border-top: 0px;
                    }
                }
            }
        }
    }
}
