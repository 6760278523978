@import 'src/assets/styles/mixins';
.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 12px 0;
    z-index: 10;
    height: 72px;
    &__with-municipality {
        @include mobile {
            height: 144px;
        }
    }
    &--white {
        background: $white;
        border-bottom: 1px solid $greyLight;
    }
    .spacer {
        flex: 1;
    }
    &__content {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 18px;
        @include mobile {
            flex-wrap: wrap;
        }
        &_part-logo {
            @include mobile {
                order: 1;
                padding: 12px 0;
            }
        }
        .municipality-header-logo-container {
            @include mobile {
                order: 2;
                width: 100%;
                justify-content: left;
                flex: auto;
            }
        }
        &_part-logo {
            @include mobile {
                order: 3;
            }
        }
        &_part-buttons {
            display: flex;
            justify-content: flex-end;
            &__content {
                margin-left: auto;
            }
            .header__content_part-search {
                justify-content: right;
            }

        }
        &_part {
            display: flex;
            &:first-child {
                flex: 1;
            }

            @include mobile {
                order: 1;
                &-search {
                    order: 2;
                    width: 100%;
                }
            }
            img {
                width: 24.3rem;
                height: auto;
                cursor: pointer;
                @include desktop {
                    width: 19rem;
                }
                @include mobile {
                    width: 115px;
                }
            }
        }
    }
    &--big {
        @include mobile {
            height: 125px;
        }
    }
}

.balcony-connect-button-spinning-button {
    min-width: 140px;
    background-color: rgb(33, 31, 38);
    border-radius:12px;
    height: 48px;
    padding: 14px;
    span.loader--white {
        border-color: rgb(253, 252, 253);
        border-bottom-color: transparent;
    };
}
