@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';

.main-select-dropdown {
    position: relative;
    min-width: 215px;
    @include mobile {
        min-width: 150px;
    }
    &--open {
        display: block;
        position: absolute;
        transform: translate(-50%, 100%);
        transition: 1s ease-in-out;
        width: 110%;
        bottom: 10px;
        left: 50%;
        z-index: 99;
        @include mobile {
            left: initial;
            right: -10px;
            transform: translate(0%, 100%);
        }
    }
    &--close {
        display: none;
        width: 100%;
        background: #ffffff;
        border-radius: 8px;
        transition: 1s ease-in-out;
    }
    &-list {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 24px 0;
        width: 100%;
        margin-top: 8px;
        background-color: $white;
        padding: 24px;
        border: 1px solid $grey200;
        border-radius: 8px;
        @include mobile {
            gap: 12px 0;
            padding: 12px;
        }
        &-item {
            @include fontStyle($ff: $Urbanist, $fs: 16px, $fw: 500, $lh: 1.18);
            color: $black;
            transition: all 0.3s ease-in;
            &--active,
            &:hover {
                color: $violet;
                opacity: 1;
            }
            @include mobile {
                font-size: 14px;
            }
        }
        li {
            display: flex;
            align-items: center;
            gap: 0 8px;
            @include fontStyle($ff: $Urbanist, $fs: 16px, $fw: 500, $lh: 1.18);
            color: $black;
            transition: all 0.3s ease-in;
            cursor: pointer;
            @include mobile {
                font-size: 14px;
                gap: 0 4px;
            }
            &:hover {
                color: $violet;
            }
        }
        &::after,
        &::before {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            transform: translate(-50%, -100%);
            top: 0;
            left: 50%;
            @include mobile {
                left: initial;
                right: 10px;
                transform: translate(0%, -100%);
            }
        }
        &::before {
            border: 10px solid transparent;
            border-bottom-color: $grey200;
            @include mobile {
                right: 9px;
            }
        }
        &::after {
            border: 9px solid transparent;
            border-bottom-color: $white;
        }
    }
}
