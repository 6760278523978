@import 'src/assets/styles/mixins';
.grid {
    padding-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    gap: 0 25px;
    @include tabletMini {
        gap: 0 20px;
    }
    @include mobileMiddle {
        gap: 0;
    }
    &__item {
        width: calc((100% - 75px) / 4);
        margin: 0 0 25px;
        @include desktop {
            width: calc((100% - 50px) / 3);
        }
        @include tabletMini {
            width: calc((100% - 40px) / 2);
            margin: 0 0 25px;
        }
        @include mobileMiddle {
            width: 100%;
            margin: 0 0 25px;
        }
    }
}
