@import 'src/assets/styles/mixins';
@import 'src/assets/styles/variables';
.sign-in-page {
    height: calc(100vh - 72px);
    @include mobile {
        margin-top: -72px;
    }
    display: flex;
    flex-direction: column;
    &__content {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        h1 {
            font-size: 65px;
            line-height: 1.1;
            color: $black;
            padding-bottom: 60px;
        }
        h3 {
            font-size: 25px;
            line-height: 1.1;
            color: $black;
        }
    }
}
