@keyframes flashbarEnter {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
  
@keyframes flashbarLeave {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}
  
.flashbar-enter {
  animation: flashbarEnter 0.2s ease-out;
  position: relative;
  width: auto;
}
  
.flashbar-leave {
  animation: flashbarLeave 0.2s ease-out;
  position: relative;
  width: auto;
  animation-fill-mode: forwards;
}
