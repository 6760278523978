@import 'src/assets/styles/mixins';
@import 'src/assets/styles/variables';

.notification-settings {
    &__content {
        .notification-settings-details-section {
            display: flex;
            flex-direction: column;
            .notification-label {
                width: 200px;
                display: flex;
                @include fontStyle($Inter, 15px,1.5,400);
            }
            .notification-toggle-container {
                display: flex;
                align-items: center;
                margin-bottom: 48px;
            }
            .notification-toggle {
                display: flex;
                width: 200px;
            }
        }
    }
}
