@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';

.main-input {
    display: flex;
    align-items: center;
    width: 100%;
    background: $greyLight;
    border: 1px solid $grey200;
    border-radius: 16px;
    padding: 13px 2rem 8px;
    height: 64px;
    transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    @include tabletMini {
        padding: 10px 12px 7px;
        height: 60px;
    }
    &--focused {
        border-color: $violet;
    }
    input {
        height: 100%;
        border: none;
        @include fontStyle($fs: 18px, $lh: 1.3, $fw: 400);
        color: $black;
    }
    &__icon {
        width: 24px;
        height: 24px;
        margin: 0 12px 0 0;
    }
    &__content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        &_label {
            @include fontStyle($fs: 12px, $lh: 1.4, $fw: 700);
            color: $black;
            margin-bottom: 4px;
            &__error {
                border: 1px solid red;
                margin-bottom: 10px;
            }
            & + input {
                @include fontStyle($fs: 14px, $lh: 1.58, $fw: 400);
            }
        }
        & + .main-input__icon {
            margin: 0 0 0 12px;
        }
    }

    span {
        color: red;
        font-size: 12px;
    }
}

.main-input-err{
    border: 1px solid #DE1B00;
}

.main-input-err_message{
    color: #DE1B00;
    padding: 0px 0 2px 5px;
    text-align: left;
    @include fontStyle($fs: 12px, $lh: 1.4, $fw: 500);
}