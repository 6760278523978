@import 'src/assets/styles/mixins';
@import 'src/assets/styles/variables';
.verify-email-address {
    height: calc(100vh - 72px);
    @include mobile {
        margin-top: -72px;
    }
    display: flex;
    flex-direction: column;
    &__content {
        flex: 1;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @include mobile {
            margin-left: 20px;
            margin-right: 20px;
        }
        h1 {
            font-size: 65px;
            line-height: 1.1;
            color: $black;
            margin-bottom: 60px;
        }
        div.verify-email-address__message {
            font-size: 25px;
            line-height: 1.1;
            color: $black;

            button.redirect-to-home {
                margin-left:5px;
                display: inline;
                h5 {
                    font-size: 25px;
                }
            }
        }
    }
}
