@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';

.table-elem {
    .w-full {
        width: 100%;
    }

    input, select {
        width: auto;
    }
}

.module-list-view-table-container {
    .tanstack-list-view {
        padding-bottom: 10px;
    }

    .tanstack-list-view {
        padding-top: 2rem;
    
        thead {
            background-color: #c6c6c6;
            th {
                padding-left: 10px;
                color: #303561
            }
        }

        tbody {
            background-color: #f4f4f4;
            td {
                padding-left: 10px;
                .link-button {
                    h3 {
                        color: #0E90DB;
                    }
                }
            }
        }
    }
}

.tanstack-list-view {
    padding-bottom: 100px;

    .tanstack-loader {
        display: flex;
        justify-content: center;
        z-index: 1;
        padding: 100px 0;
    }

    table {
        thead {
            th {
                @include fontStyle($fs: 18px, $lh: 1.6, $fw: 500);
                color: $grey;
                height: 42px;
                text-align: left;
                vertical-align: middle;
                white-space: nowrap;
            }
        }

        tr {
            &.pointer {
                transition: all 0.3s ease-in-out;
                cursor: pointer;
                &:hover {
                    background: rgba($color: $violetLight, $alpha: 0.7);
                }
            }
            &.height-medium {
                height: 60px;
                td {
                    vertical-align: middle;
                }
            }
            h3 {
                font-size: 14px;
                font-weight: 600;
            }
        }
    }

    .flex {
        display: flex;
    }

    .controls-line {
        height: 58px;
        justify-content: flex-end;
        align-items: center;
    
        .pagination-button {
            height: 24px;
            width: 24px;
            cursor: pointer;
        }
        .pagination-button:disabled {
            opacity: 30%;
        }

        .forward {
            svg {
                transform: rotate(180deg)
            }
            margin-right: 14px;
            margin-left: 34px;
        }

        .page-indicator {
            margin-right: 20px;
            font-size: 13px;
            line-height: 20px;
            font-weight: 400;
            color: rgba(0,0,0,0.6)
        }

        .page-size-select {
            .base-select__addition {
                margin-right: 20px;
                font-size: 13px;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.87);
                line-height: 20px

            }
            .base-select__select-input {
                line-height: 20px;
                color: rgba(0, 0, 0, 0.87);
                font-weight: 400;
                font-size: 13px;
            }
            .base-select {
                .dropdown {
                    &__list {
                        &_item {
                            font-size: 13px;
                            color: rgba(0, 0, 0, 0.87);
                        }
                    }
                }
            }
            margin-right: 10px
        }
    }
}
