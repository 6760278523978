@import 'src/assets/styles/variables';
.info-card {
    display: flex;
    align-items: center;
    &__content {
        display: flex;
        gap: 4px 0;
    }
    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 6.4rem;
        height: 6.4rem;
        min-width: 6.4rem;
        min-height: 6.4rem;
        border-radius: 16px;
        background-color: $violetLight;
        margin-right: 16px;
    }
    .info-card__icon + .info-card__content {
        width: calc(100% - 6.4rem - 16px);
        padding-right: 10px;
    }
    .link-button h3 {
        color: $violet
    }
    h4 {
        color: $grey600;
        line-height: 1;
        font-weight: 500;
    }
    h3 {
        font-weight: 600;
        color: $black;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        &.clickable {
            cursor: pointer;
        }
    }
}
